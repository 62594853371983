<div class="login-form">
  <ng-container *ngIf="!isTattaAdminUserDirectLogin">
    <ng-container *ngIf="!isNeedCompleteNewPassword">
      <h1 class="common-title title">ログイン</h1>

      <form (ngSubmit)="login()">
        <ul class="form-list">
          <li>
            <p class="form-name">メールアドレス</p>
            <p><input type="email" class="wFull" [formControl]="emailFormCtl" (focus)="resetTouched(emailFormCtl)"></p>
            <app-validate-error [formCtl]="emailFormCtl"></app-validate-error>
          </li>
          <li>
            <p class="form-name">パスワード<span class="password-remainder"><a routerLink="/auth/login/forget-password">パスワードを忘れた方はこちら</a></span></p>
            <p><input type="password" class="wFull" [formControl]="passwordFormCtl" (focus)="resetTouched(passwordFormCtl)"></p>
            <app-validate-error [formCtl]="passwordFormCtl"></app-validate-error>
          </li>
        </ul>

        <div class="btn-wrapper">
          <button type="submit" class="common-btn-submit l">ログイン</button>
        </div>
      </form>
    </ng-container>

    <ng-container *ngIf="isNeedCompleteNewPassword">
      <h1 class="common-title title">ログイン（パスワード変更）</h1>
      <p class="information">最初にパスワードの変更を行ってください。</p>
      <form (ngSubmit)="completeNewPassword()">
        <ul class="form-list">
          <li>
            <p class="form-name">新しいパスワード</p>
            <p class="password-condition">※ 8文字以上、半角大文字/小文字/数字をそれぞれ1文字以上</p>
            <p><input type="{{isShowNewPassword ? 'text' : 'password'}}" class="wFull" [formControl]="newPasswordFormCtl" autocomplete="new-password" (focus)="resetTouched(newPasswordFormCtl)"></p>
            <p class="password-toggle"><app-checkbox [(ngModel)]="isShowNewPassword" [name]="'showPassword'">パスワードを表示する</app-checkbox></p>
            <app-validate-error [formCtl]="newPasswordFormCtl"></app-validate-error>
          </li>
          <li>
            <p class="form-name">新しいパスワード（再入力）</p>
            <p><input type="password" class="wFull" [formControl]="newPasswordConfirmFormCtl" autocomplete="new-password" (focus)="resetTouched(newPasswordConfirmFormCtl)"></p>
            <app-validate-error [formCtl]="newPasswordConfirmFormCtl"></app-validate-error>
          </li>
        </ul>

        <div class="btn-wrapper">
          <button type="submit" class="common-btn-submit l">ログイン</button>
        </div>
      </form>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="isTattaAdminUserDirectLogin">
    <h1 class="common-title title">ログイン</h1>
    <p class="information">ログインに成功しました。<br><br>TATTA Adminから表示したいチームのリンクをクリックしてアクセスしてください。</p>
  </ng-container>
</div>
