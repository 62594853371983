import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl, Validators} from "@angular/forms";
import {AuthService, NEW_PASSWORD_REQUIRED} from "../../../../core/services/auth.service";

import {DialogService} from "../../../../core/services/dialog.service";
import {UtilService} from "../../../../core/services/util.service";
import {TermOfServiceComponent} from "../../../common/term-of-service/term-of-service.component";
import {TeamSelecterComponent} from "../../../common/team-selecter/team-selecter.component";
import {TeamAuthorization} from "../../../../core/model/user";
import {OfficialTeam} from "../../../../core/model/resources/team";
import {TeamApiService} from "../../../../core/services/team-api.service";
import {TeamService} from "../../../../core/services/team.service";
import {LoadingService} from "../../../../core/services/loading.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  // TATTA Adminから遷移してきた場合は「/teams/:teamId」でアクセスされた直後に「/auth/login/:teamId」にリダイレクトさせるため、
  // TATTA Adminで指定されたteamIdを取得する。
  urlTeamId: number | undefined = this.route.snapshot.paramMap.get('teamId')
    ? Number(this.route.snapshot.paramMap.get('teamId')) : undefined;
  isNeedCompleteNewPassword: boolean = false;

  emailFormCtl: FormControl<string> = new FormControl<string>(
    '', {validators: [Validators.required, Validators.email], nonNullable: true}
  );
  passwordFormCtl: FormControl<string> = new FormControl<string>(
    '', {validators: [Validators.required], nonNullable: true}
  );

  newPasswordFormCtl: FormControl<string> = new FormControl<string>(
    '', {validators: [Validators.required], nonNullable: true}
  );
  newPasswordConfirmFormCtl: FormControl<string> = new FormControl<string>(
    '', {validators: [Validators.required], nonNullable: true}
  );
  isShowNewPassword: boolean = false;

  isTattaAdminUserDirectLogin: boolean = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private authService: AuthService,
              private teamService: TeamService,
              private teamApiService: TeamApiService,
              private dialogService: DialogService,
              private loadingService: LoadingService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.checkBrowser();

    const isSuccess = await this.authService.loginByCache();
    if (isSuccess) {
      console.log('Redirect to the secure site.');
      const user = await this.authService.getUser();
      const team = await LoginComponent.getTeam(this.teamApiService, this.dialogService, this.loadingService, user?.teamAuthorization);
      await this.moveToSecureSite(team);
      return;
    }
  }

  // IE/Firefox/Operaでアクセスされた場合に注意事項メッセージを表示する
  // Edge/Chrome/Safariの最新版を推奨するが、最新版ではないEdge/Chrome/Safariでアクセスされた場合でも特に表示は行わない
  async checkBrowser(): Promise<void> {
    let isValid = true;
    const userAgent = window.navigator.userAgent.toLowerCase();

    if (userAgent.indexOf('msie') != -1 ||
      userAgent.indexOf('trident') != -1) {
      // IE
      isValid = false;
    } else if (userAgent.indexOf('edge') != -1) {
      // Chromiumベースでない旧Edge（ChromiumベースのEdgeの識別子は「edg」として別に存在する）
      isValid = false;
    } else if (userAgent.indexOf('firefox') != -1) {
      // Firefox
      isValid = false;
    } else if (userAgent.indexOf('opera') != -1) {
      //Opera
      isValid = false;
    }

    if (!isValid) {
      await this.dialogService.information({
        title: '本サービスの推奨環境について',
        content: 'お使いのブラウザは本サービスの推奨ブラウザではありません。本サービスを正常にご利用いただけない可能性がございますので、以下の環境でのご利用を推奨いたします。\n\n' +
          '■Windows\n' +
          '- Microsoft Edge（最新版）\n' +
          '- Google Chrome（最新版）\n' +
          '\n' +
          '■Mac\n' +
          '- Safari（最新版）\n' +
          '- Google Chrome（最新版）\n'
      });
    }
  }

  resetTouched(formCtl: FormControl): void {
    UtilService.markAsUntouched(formCtl);
  }

  async validateLogin(email: string, password: string): Promise<boolean> {
    const errMessageList: string[] = [];

    if (email === '') {
      UtilService.markAsTouchedAndDirty(this.emailFormCtl);
      errMessageList.push('メールアドレスを入力してください');
    }

    if (password == '') {
      UtilService.markAsTouchedAndDirty(this.passwordFormCtl);
      errMessageList.push('パスワードを入力してください');
    }

    if (errMessageList.length > 0) {
      await this.dialogService.alert({title: 'ログインエラー', content: UtilService.getErrMessage(errMessageList)});
      return false;
    }

    return true;
  }

  async login(): Promise<void> {
    const email: string = this.emailFormCtl.value;
    const password: string = this.passwordFormCtl.value;

    if (!(await this.validateLogin(email, password))) {
      return;
    }

    const result = await this.authService.login(email, password);
    if (result.isSuccess) {
      const team = await LoginComponent.getTeam(this.teamApiService, this.dialogService, this.loadingService, result.user?.teamAuthorization);
      await this.moveToSecureSite(team);
      return;
    } else {
      if (result.message === NEW_PASSWORD_REQUIRED) {
        const agreed = await this.dialogService.customComponent(TermOfServiceComponent, {withAgreeOption: true});
        if (agreed) {
          this.isNeedCompleteNewPassword = true;
        }
        return;
      } else {
        await this.dialogService.alert({title: 'ログインエラー', content: result.message!});
        return;
      }
    }
  }

  async validateNewPassword(newPassword: string, newPasswordConfirm: string): Promise<boolean> {
    const errMessageList: string[] = [];

    if (newPassword === '') {
      UtilService.markAsTouchedAndDirty(this.newPasswordFormCtl);
      errMessageList.push('新しいパスワードを入力してください');
    }

    if (newPasswordConfirm === '') {
      UtilService.markAsTouchedAndDirty(this.newPasswordConfirmFormCtl);
      errMessageList.push('新しいパスワード（再入力）を入力してください');
    }

    if (newPassword !== newPasswordConfirm) {
      errMessageList.push('新しいパスワード（再入力）に異なるパスワードが入力されています。');
    }

    if (errMessageList.length > 0) {
      await this.dialogService.alert({content: UtilService.getErrMessage(errMessageList)});
      return false;
    }

    return true;
  }

  async completeNewPassword(): Promise<void> {
    const newPassword: string = this.newPasswordFormCtl.value;
    const newPasswordConfirm: string = this.newPasswordConfirmFormCtl.value;

    if (!(await this.validateNewPassword(newPassword, newPasswordConfirm))) {
      return;
    }

    const ret = await this.authService.completeNewPassword(newPassword);
    if (ret.isSuccess) {
      const team = await LoginComponent.getTeam(this.teamApiService, this.dialogService, this.loadingService, ret.user?.teamAuthorization);
      await this.moveToSecureSite(team);
      return;
    } else {
      await this.dialogService.alert({content: ret.message!});
      return;
    }
  }

  static async getTeam(teamApiService: TeamApiService, dialogService: DialogService,
                       loadingService: LoadingService, teamAuthorization?: TeamAuthorization, showClose?: boolean): Promise<OfficialTeam | null> {
    if (!teamAuthorization) {
      return null;
    }

    // hideに300msec waitしているため、連続実行時にはここでも待つ
    try {
      await new Promise(resolve => setTimeout(resolve, 300));
      loadingService.show();

      if (teamAuthorization.adminTeamIds.length === 1 && teamAuthorization.ambassadorTeamIds.length === 0) {
        return await teamApiService.getTeam(teamAuthorization.adminTeamIds[0]);
      } else if (teamAuthorization.adminTeamIds.length === 0 && teamAuthorization.ambassadorTeamIds.length === 1) {
        return await teamApiService.getTeam(teamAuthorization.ambassadorTeamIds[0]);
      } else {
        const teams: OfficialTeam[] = [];
        const teamIds = teamAuthorization.adminTeamIds.concat(teamAuthorization.ambassadorTeamIds);
        await Promise.all(teamIds.map(async teamId => {
          const team = await teamApiService.getTeam(teamId, true);
          if (team) {
            teams.push(team);
          }
        }));
        teams.sort((a, b) => a.id - b.id)
          .sort((a, b) => a.status - b.status);
        return await dialogService.customComponent<OfficialTeam>(TeamSelecterComponent, {
          teams,
          showClose: !!showClose
        });
      }
    } finally {
      loadingService.hide();
    }
  }

  private async moveToSecureSite(team: OfficialTeam | null): Promise<void> {
    if (!team) {
      if (this.urlTeamId !== undefined) {
        const urlTeam = await this.teamApiService.getTeam(this.urlTeamId);
        if (urlTeam) {
          this.teamService.setTeam(urlTeam);
          await this.router.navigate([`${this.urlTeamId}/licence`]);
        }
        return;
      } else {
        this.isTattaAdminUserDirectLogin = true;
        return;
      }
    } else {
      this.teamService.setTeam(team);
      await this.router.navigate([`${team.id}/licence`]);
      return;
    }
  }
}
